import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Accordion from 'react-bootstrap/Accordion';

//import ReactGA from 'react-ga';

class EventCard extends Component{

    render(){
        const descriptions = {
            "Idea Exchange" : "Meet with Autodesk product and design teams to share your ideas about the tools you use every day.",
            "Answer Bar" : "Get answers to your questions from product experts in the Answer Bar.",
            "Quads" : "Visit industry quads in the Expo for presentations, conversations, and activities",
            "Meetups" : "Connect with like-minded peers at unique meetups hosted throughout the conference.",
            "Lightning Talks" : "Watch short, dynamic presentations from industry experts and thought leaders."
        };



        let now = new Date();
        now.setMinutes(now.getMinutes() + 30); // Gives 30 mins of time to recommend
        // create the list of valid events
        let validEvents = this.props.events.map( (event) => {
            let validTimes = null;
            if (Array.isArray(event['datetime'])){
                validTimes = event['datetime'].map( (time) =>{
                    if(time.toLowerCase() === 'ongoing'){
                        return('All Day');
                    }else if (now < new Date(time.split('-')[0])){
                        return new Date(time.split('-')[0]).toLocaleTimeString({timeZone:"America/Los Angeles"});
                    }else{
                        return null;
                    }
                });
                event['dateString'] = validTimes;
            } else {
                if( event['datetime'].toLowerCase() === 'ongoing'){
                    validTimes = 'All Day';
                }else if (now < new Date( event['datetime'].split('-')[0])){
                    validTimes = new Date(event['datetime'].split('-')[0]).toLocaleTimeString({timeZone:"America/Los Angeles"});
                }else{
                    validTimes = null;
                }
                event['dateString'] = [validTimes];
            }
            return event;

        });

        const eventRows = validEvents.map((event) => {
            //console.log(event['dateString']);
            if (event['dateString'] && !event['dateString'].every( (item) => item == null)){

                const dateString = event["dateString"].join(',\n');


                return(
                    <Card key={event['title']+event['dateString']+event['location']}>
                        <Card.Body>
                        <Card.Title>{event['title']}</Card.Title>
                        <Card.Subtitle>{event['location']}</Card.Subtitle>
                        <Card.Text>{dateString}</Card.Text>
                        </Card.Body>
                    </Card>);
            }else{
                return null;
            }
        });

        let numEvents =  Math.min(5, eventRows.filter( (row) => row !== null).length); // only count up to five events
        

        return(
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={this.props.eventkey}> 
                    {this.props.eventname} ({numEvents})
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={this.props.eventkey}>
                    <Card.Body>
                        <div className="mb-3"><small>{descriptions[this.props.eventname]}</small></div>
                                {eventRows.every((row) => row == null) ? <tr><td colSpan="3"><em>No Recommended Events Found</em></td></tr> : eventRows.slice(0,4)}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        );
    }
}
class DayAccordion extends Component{
    render(){
        if(this.props.events){

            const eventCards =  Object.entries(this.props.events).map(([key, value])=> 
                <EventCard eventname={key} key={this.props.day+key} 
                    eventkey={this.props.day+key} events={value}> </EventCard>
            );
            return(
                <div className="DayAccordion mt-1">
                    <h1>{this.props.day}</h1>
                    <Accordion >
                        {eventCards} 
                    </Accordion>
                </div>
            );
        }
    }
}

class App extends Component{
    constructor(props){
        super(props);
        this.state = {data: '', isLoaded: false}

    }
    /*   componentDidMount() {
    //        ReactGA.initialize('G-BSQCJC3CE8');
    //        ReactGA.pageview(window.location.pathname +  window.location.search); 
    }*/
    render(){
        let userID;
        try{
            const idRe = /id=([A-z0-9]+)/;
            userID = window.location.search.match(idRe)[1];
        }catch(error){
            return(<h1>No ID provided.</h1>); 
        }

        if(!this.state.isLoaded){
            //console.log(process.env.PUBLIC_URL+'data/'+userID[0]+'/'+userID+'.json');
            fetch(process.env.PUBLIC_URL+'data/'+userID[0]+'/'+userID+'.json')
                .then(response => { 
                    if (!response.ok){
                        //console.log('uknown user');
                        fetch(process.env.PUBLIC_URL+'data/-/-.json')
                            .then(response => response);
                    }else{
                        return response;

                    }})
                .then(response => response.json())
                .then(data => this.setState({"data" : data, isLoaded: true}))
                .catch(error => {
                    //console.log(error);
                    fetch(process.env.PUBLIC_URL+'data/-/-.json')
                        .then(response => response.json())
                        .then(data => this.setState({"data" : data, isLoaded: true}));
                });
        }   
        const schedule = this.state.data['schedule'];
        let dayAccordions = 'Loading...';
        if(schedule){
            const firstDayofAU = new Date('18 November 2019');
            const today = new Date();
            let dayArray =  Object.entries(schedule);

            if (firstDayofAU.getYear() === today.getYear() && firstDayofAU.getMonth() === today.getMonth()){
                dayArray = dayArray.slice(Math.min(Math.max(0, (today.getDate() - firstDayofAU.getDate())),3));
            }

            dayAccordions = dayArray.map(([key, value])=>
                <DayAccordion key={key} day={key} events={value} />
            ); 
        }
        return (
            <Container className="p-3 ">
                <Jumbotron fluid>
                    <h1>What is this?</h1>
                    <p fluid>This is a list of events you might like to attend that are not
                        part of the regular schedule. The list below shows some events you might be interested in.</p>
                        <Button className="btn-dark m-2" href="https://autodeskfeedback.az1.qualtrics.com/jfe/preview/SV_7Ql2uWOlbWNgPel?Q_SurveyVersionID=current&Q_CHL=preview">Was this helpful?</Button>
                    </Jumbotron>

                    {dayAccordions}

                </Container>
        );
    }
}

export default App;
